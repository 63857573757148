import { Component } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { ActualStockEditService } from './actual-stock-edit.service';
import { BreadcrumbViewComponent } from '../../../framework/breadcrumb-view.component';

@Component({
	selector: 'app-actual-stock',
	templateUrl: './actual-stock.component.html'
})
export class ActualStockComponent extends BreadcrumbViewComponent {
	public edit: ActualStockEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.actualStock, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = 'actualStock';
	}

	onCellPreparedEv(event: any) {
		if (event.rowType === 'data' && event.row && event.row.data) {
			if (event.row.data.remain < 0) {
				event.cellElement.classList.add('dx-red');
			}
			if (event.row.data.remain === 0.0) {
				event.cellElement.classList.add('dx-bold');
			}
		}
	}
}
