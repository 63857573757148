import { iBisViewComponent } from '@dohu/ibis-common';
import { Component, ViewChild, Input } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { UnitEditService } from './unit-edit.service';

@Component({
    selector: 'app-unit-kpi',
    templateUrl: 'unit-kpi.component.html'
})
export class UnitKpiComponent extends iBisViewComponent {

    @Input() partyId: any;
    @ViewChild('gridUnitKpi') grid: DxDataGridComponent;

    public edit: UnitEditService;
    constructor(public proxy: ProxyService) {
        super(proxy.unit, proxy.lg, proxy.iui, proxy.activatedRoute);
        if (!this.key) {
            this.key = 'unitKpi';
        }
    }

    public onGridInit(e: any) {
        if (e.component.option('dataSource') == null) {
            e.component.option('dataSource', this.edit.getPartyKpi(this.partyId));
        }
    }

    public onRowInserting(event: any) {
        event.data.partyId = this.partyId;
    }
}
