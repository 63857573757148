import notify from 'devextreme/ui/notify';
import { Injectable } from '@angular/core';
import { iBisEntityService } from '@dohu/ibis-entity';
import { ActivatedRoute, Router } from '@angular/router';
import { iBisNavigationService } from '@dohu/ibis-navigation';
import { TaxEditService } from '../common/control/tax/tax-edit.service';
import { UnitEditService } from '../common/party/unit/unit-edit.service';
import { CollectEditService } from '../waste/collect/collect-edit.service';
import { InvoiceEditService } from '../common/invoice/invoice-edit.service';
import { AlertEditService } from '../waste/report/alert/alert-edit.service';
import { ControlEditService } from '../common/control/control-edit.service';
import { TransferEditService } from '../waste/transfer/transfer-edit.service';
import { GenerateEditService } from '../waste/generate/generate-edit.service';
import { ReportExtEditService } from '../waste/report/report-ext-edit.service';
import { AgencyEditService } from '../common/party/agency/agency-edit.service';
import { ReportIntEditService } from '../waste/report/report-int-edit.service';
import { PermitEditService } from '../common/party/permit/permit-edit.service';
import { ContractEditService } from '../common/contract/contract-edit.service';
import { PartyEditService } from '../common/party/party-edit/party-edit.service';
import { TransportEditService } from '../waste/transport/transport-edit.service';
import { TreatmentEditService } from '../waste/treatment/treatment-edit.service';
import { ArchiveEditService } from '../waste/report/archive/archive-edit.service';
import { AuditorEditService } from '../safety/admin/auditor/auditor-edit.service';
import { ProfileEditService } from '../safety/admin/profile/profile-edit.service';
import { WasteTypeEditService } from '../waste/waste-type/waste-type-edit.service';
import { UnitWasteEditService } from '../common/party/unit/unit-waste-edit.service';
import { LocationEditService } from '../safety/admin/location/location-edit.service';
import { IncinerateEditService } from '../waste/incinerate/incinerate-edit.service';
import { ProjectEditService } from '../common/contract/project/project-edit.service';
import { EnumTypeEditService } from '../common/enum/enum-type/enum-type-edit.service';
import { GeneralReportEditService } from '../waste/report/general-report-edit.service';
import { PartyWizardService } from '../common/party/party-wizard/party-wizard.service';
import { PartyLinkEditService } from '../common/party/party-link/party-link-edit.service';
import { DepartmentEditService } from '../safety/admin/department/department-edit.service';
import { TransporterEditService } from '../common/party/transporter/transporter-edit.service';
import { ActualStockEditService } from '../waste/stock/actual-stock/actual-stock-edit.service';
import { MonthlyStockEditService } from '../waste/stock/monthly-stock/monthly-stock-edit.service';
import { InvoiceItemEditService } from '../common/invoice/invoice-item/invoice-item-edit.service';
import { PermissionEditService } from '../safety/admin/auditor/permission/permission-edit.service';
import { TransportFinishService } from '../waste/transport/transport-finish/transport-finish.service';
import { GeographicAreaEditService } from '../common/enum/geographic-area/geographic-area-edit.service';
import { MonthlyStockRecheckService } from '../waste/stock/monthly-stock/monthly-stock-recheck.service';
import { SafetyPlanningEditService } from '../safety/audit/safety-planning/safety-planning-edit.service';
import { ContractAnnexEditService } from '../common/contract/contract-annex/contract-annex-edit.service';
import { TransportFullEditService } from '../waste/transport/transport-full/transport-full-edit.service';
import { ControlMeasureEditService } from '../common/control/control-measure/control-measure-edit.service';
import { ProfileCategoryEditService } from '../safety/admin/profile/category/profile-category-edit.service';
import { PartyWorkCenterEditService } from '../common/party/party-work-center/party-work-center-edit.service';
import { ControlSanctionEditService } from '../common/control/control-sanction/control-sanction-edit.service';
import { iBisLanguageService, iBisSupportService, iBisUIService, iBisValidationService } from '@dohu/ibis-common';
import { TransportApprovalEditService } from '../waste/transport/transport-approval/transport-approval-edit.service';
import { iBisAuthService, iBisChangePasswordService, iBisEditUserService, iBisInviteUserService } from '@dohu/ibis-auth';
import { SafetyPlanningCopyService } from '../safety/audit/safety-planning/safety-planning-copy/safety-planning-copy.service';
import { InconsistentEditService } from '../safety/audit/inconsistent/inconsistent-edit.service';
import { SafetyReportEditService } from '../safety/audit/safety-report/safety-report-edit.service';
import { KpiAuditorEditService } from '../safety/analize/kpi-auditor/kpi-auditor-edit.service';
import { KpiLocationEditService } from '../safety/analize/kpi-location/kpi-location-edit.service';
import { SafetyReportWizardService } from '../safety/audit/safety-report/safety-report-wizard.service';
import { KPIAnalyzesFilterService } from '../waste/analize/kpi-analyzes/kpi-analyzes-filter.service';
import { SafetyPlanningExceptEditService } from '../safety/audit/safety-planning/safety-planning-except/safety-planning-except.service';
import { TransportApprovalPartyEditService }
	from '../waste/transport/transport-approval/transport-approval-party/transport-approval-party-edit.service';
import { MirrorStockEditService } from '../waste/stock/mirror-stock/mirror-stock-edit.service';
import { DashboardAnalyzesFilterService } from '../waste/analize/dashboard-analyzes/dashboard-analyzes-filter.service';
import { TaxAnalyzesFilterService } from '../waste/analize/tax-analyzes/tax-analyzes-filter.service';
import { CostAnalyzesFilterService } from '../waste/analize/cost-analyzes/cost-analyzes-filter.service';
import { IncomeAnalyzesFilterService } from '../waste/analize/income-analyzes/income-analyzes-filter.service';
import { ControlAnalyzesFilterService } from '../waste/analize/control-analyzes/control-alanyzes-filter.service';
import { CapitalizationAnalyzesFilterService } from '../waste/analize/capitalization-analyzes/capitalization-alanyzes-filter.service';
import { DashboardBoxesService } from '../waste/analize/dashboard-boxes/dashboard-boxes.service';

@Injectable()
export class ProxyService {

	constructor(public entity: iBisEntityService, public lg: iBisLanguageService, public iui: iBisUIService,
		public auth: iBisAuthService, public nav: iBisNavigationService,
		public user: iBisEditUserService, public validator: iBisValidationService,
		public generate: GenerateEditService, public partyWizard: PartyWizardService,
		public contract: ContractEditService, public transport: TransportEditService, public transportFull: TransportFullEditService,
		public transportApproval: TransportApprovalEditService, public transportFinish: TransportFinishService,
		public transfer: TransferEditService, public permit: PermitEditService,
		public changePassword: iBisChangePasswordService, public invite: iBisInviteUserService, public support: iBisSupportService,
		public controlMeasure: ControlMeasureEditService, public controlSanction: ControlSanctionEditService,
		public project: ProjectEditService, public control: ControlEditService, public incinerate: IncinerateEditService,
		public tax: TaxEditService, public collect: CollectEditService, public archive: ArchiveEditService,
		public contractAnnex: ContractAnnexEditService, public alert: AlertEditService,
		public generalReport: GeneralReportEditService, public reportExt: ReportExtEditService,
		public treatment: TreatmentEditService, public reportInt: ReportIntEditService,
		public enumType: EnumTypeEditService, public geo: GeographicAreaEditService,
		public agency: AgencyEditService, public party: PartyEditService, public unit: UnitEditService,
		public workCenter: PartyWorkCenterEditService, public partyLink: PartyLinkEditService, public transporter: TransporterEditService,
		public unitWaste: UnitWasteEditService, public monthlyStock: MonthlyStockEditService, public actualStock: ActualStockEditService,
		public wasteType: WasteTypeEditService, public monthlyStockRecheck: MonthlyStockRecheckService,
		public mirrorStock: MirrorStockEditService, public invoice: InvoiceEditService, public invoiceItem: InvoiceItemEditService,
		public auditor: AuditorEditService, public permission: PermissionEditService,
		public department: DepartmentEditService, public profile: ProfileEditService,
		public profileCategory: ProfileCategoryEditService, public location: LocationEditService,
		public safetyPlanningCopy: SafetyPlanningCopyService, public safetyPlanning: SafetyPlanningEditService,
		public safetyPlanningExcept: SafetyPlanningExceptEditService, public inconsistent: InconsistentEditService,
		public safetyReport: SafetyReportEditService, public kpiAuditor: KpiAuditorEditService, public kpiLocation: KpiLocationEditService,
		public kpiAnalyzes: KPIAnalyzesFilterService, public dashboardAnalyzes: DashboardAnalyzesFilterService,
		public transportApprovalParty: TransportApprovalPartyEditService, public taxAnalyzes: TaxAnalyzesFilterService,
		public costAnalyzes: CostAnalyzesFilterService, public incomeAnalyzes: IncomeAnalyzesFilterService,
		public dashboardBoxes: DashboardBoxesService,
		public controlAnalyzes: ControlAnalyzesFilterService, public capitalizationAnalyzes: CapitalizationAnalyzesFilterService,
		public safetyReportWizard: SafetyReportWizardService, public router: Router, public activatedRoute: ActivatedRoute) {

		this.auth.onAuthFinished.subscribe((manual) => {
			this.entity.execute('GetSingleFilterParty', {}).then(data => {
				this.party.defaultBusinessUnit = data.bu ?? null;
				this.party.defaultEntity = data.ent ?? null;
				this.party.defaultBusinessUnit = data.bu ?? null;
				if (!this.party.defaultEntity) {
					this.partyWizard.showPopup({});
				}
			}, error => {
				this.lg.showError(error);
			});
		});

		this.lg.locale('ro');
		nav.onShowPopup.subscribe((item) => {
			switch (item) {
				case 'generate':
					this.generate.showPopup(null);
					break;
				case 'transport':
					this.transport.showPopup(null);
					break;
				case 'contract':
					this.contract.showPopup(null);
					break;
				case 'permit':
					this.permit.showPopup(null);
					break;
				case 'party':
					this.party.showPopup(null);
					break;
				case 'change-password':
					this.changePassword.showPopup(null);
					break;
				case 'invite':
					this.invite.showPopup(null);
					break;
				case 'support':
					this.support.showPopup(null);
					break;
			}
		});

		this.entity.onError.subscribe(error => {
			if (error.status === 401) {
				// auth.logout();
				notify('Authorization has expired. Retry to login.', 'error', 3000);
				this.auth.reset();
				this.router.navigateByUrl('/login', { skipLocationChange: true });
			}
		});
	}

	public resetValidation(component: any) {
		if (component) {
			component.reset();
			component.option('isValid', true);
		}
	}

	public enumDisplay(info: any) {
		return info ? info.code + ' - ' + (info.description || '') : '';
	}

	public get allowEdit(): boolean {
		return this.auth.tabAccess.edit;
	}

	public get allowDelete(): boolean {
		return this.auth.tabAccess.delete;
	}

	public get showActions(): boolean {
		return this.allowEdit || this.allowDelete;
	}

	public get allowedActions(): number {
		return (this.allowEdit ? 1 : 0) + (this.allowDelete ? 1 : 0);
	}

	public get actionsWidth(): number {
		return this.allowedActions * (this.iui.config.ui.actionWidth ?? 35);
	}
}
