import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { iBisCommonModule } from '@dohu/ibis-common';
import { iBisNavigationModule } from '@dohu/ibis-navigation';
import { FrameworkModule } from '../../framework/framework.module';
import { ActualStockComponent } from './actual-stock/actual-stock.component';
import { MirrorStockComponent } from './mirror-stock/mirror-stock.component';
import { MonthlyStockComponent } from './monthly-stock/monthly-stock.component';
import { ActualStockEditComponent } from './actual-stock/actual-stock-edit.component';
import { MonthlyStockEditComponent } from './monthly-stock/monthly-stock-edit.component';
import { MonthlyStockRecheckComponent } from './monthly-stock/monthly-stock-recheck.component';
import { ActualStockEditService } from './actual-stock/actual-stock-edit.service';
import { MonthlyStockEditService } from './monthly-stock/monthly-stock-edit.service';
import { MonthlyStockRecheckService } from './monthly-stock/monthly-stock-recheck.service';


@NgModule({
	declarations: [
		MirrorStockComponent,
		ActualStockComponent,
		MonthlyStockComponent,
		ActualStockEditComponent,
		MonthlyStockEditComponent,
		MonthlyStockRecheckComponent
	],
	imports: [
		DxFormModule,
		BrowserModule,
		DxPopupModule,
		FrameworkModule,
		iBisCommonModule,
		iBisNavigationModule,
		DxDataGridModule,
		DxSelectBoxModule
	],
	exports: [
		MirrorStockComponent,
		ActualStockComponent,
		MonthlyStockComponent,
		ActualStockEditComponent
	],
	providers: [
		ActualStockEditService,
		MonthlyStockEditService,
		MonthlyStockRecheckService
	]
})
export class StockModule { }
