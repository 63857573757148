import notify from 'devextreme/ui/notify';
import DataSource from 'devextreme/data/data_source';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { DxMultiViewComponent } from 'devextreme-angular/ui/multi-view';
import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ProxyService } from '../../service/proxy.service';


@Component({
	selector: 'app-wizard-gentrans',
	templateUrl: 'generareTransport.component.html'
})
export class GenerareTransportComponent extends iBisBaseComponent {
	@Input() isGenTransVisible: boolean;
	@Output() closePopup = new EventEmitter<any>();
	@ViewChild('multiview') multiview: DxMultiViewComponent;

	public multiViewItems: DataSource;

	public wizardState: number;

	public wizardValidation: string;
	public wizardValidationArr: string[];

	public onFinish: any;
	public goToNext: any;
	public newTransport: any;
	public goToPrevious: any;
	public generateModel: any;
	public finishTransport: any;
	public showTransportData: any;

	constructor(public proxy: ProxyService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);

		this.isGenTransVisible = false;
		this.wizardValidationArr = ['gdValidation', 'addTransProgValidation', 'addFinishTransValidation'];

		this.goToPrevious = this.goToPreviousEv.bind(this);
		this.goToNext = this.gotoNextEv.bind(this);
		this.onFinish = this.onFinishEv.bind(this);
		this.initMultiView();
	}

	onFinishEv(event: any) {
		event.event.preventDefault();
		this.closeWizard(event);
	}

	gotoNextEv(event: any) {
		event.event.preventDefault();
		const validation = event.validationGroup.validate();
		if (!validation.isValid) {
			notify(this.L('ALL_REQ_FIELDS', null), 'error', 3000);
			return;
		}
		this.wizardValidation = this.wizardValidationArr[this.wizardState];
		const current = this.multiview.instance.option('selectedIndex');
		this.wizardState = current + 1;
		this.multiview.instance.option('selectedIndex', current + 1);
	}

	goToPreviousEv(event: any) {
		event.event.preventDefault();
		const current = this.multiview.instance.option('selectedIndex');
		this.wizardState = current - 1;
		this.multiview.instance.option('selectedIndex', current - 1);
	}

	closeWizard(event: any) {
		const current = this.multiview.instance.option('selectedIndex');
		this.isGenTransVisible = false;
		this.wizardState = 0;
		this.closePopup.emit(this.isGenTransVisible);
	}

	initMultiView() {
		this.wizardState = 0;
		this.wizardValidation = this.wizardValidationArr[this.wizardState];
		this.generateModel = { g_date: new Date() };
		this.newTransport = { t_sendDate: new Date() };
		this.finishTransport = {};
		this.showTransportData = {};

		this.multiViewItems = new DataSource({
			store: [{
				title: this.L('GENERATE', null)
			}, {
				title: this.L('TRANSPORT', null),
			}, {
				title: this.L('TRANSPORT_COMPLETION', null),
			}],
			// map: (itemData) => {
			//     itemData.dataArray = Object.keys(itemData.data).map((key) => {
			//         return {
			//             propertyKey: key,
			//             propertyValue: itemData.data[key]
			//         };
			//     });
			//     return itemData;
			// }
		});
	}
}
