/* eslint-disable no-bitwise */
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import * as sd from '../../assets/static.json';
import { iBisAuthService } from '@dohu/ibis-auth';
import { HttpClient } from '@angular/common/http';
import CustomStore from 'devextreme/data/custom_store';
import { iBisLanguageService } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
@Injectable()
export class DataService {
	public isButtonDisable: boolean;
	public allowRegister: boolean;
	public common: any;

	// safety
	public currentAuditorId: string;
	public currentProfileId: string;

	isLoginModalVisible: any;
	firstMonthDay: Date;
	lastMonthDay: Date;

	defaultParty: { bu: string; ent: string; wc: string };

	constructor(public router: Router, public http: HttpClient, public auth: iBisAuthService, public lg: iBisLanguageService,
		private entity: iBisEntityService) {
		this.common = {
			storageType: this.getEnumValue('Stocare'),
			eeeCategory: this.getEnumValue('EEE'),
			transportType: this.getEnumValue('Transport'),
			treatmentTypes: this.getEnumValue('Tratament'),
			destinationTypes: this.getEnumValue('Destinatie'),
			capitalization: this.getEnumValue('Valorificare'),
			elimination: this.getEnumValue('Eliminare')
		};
		this.firstMonthDay = new Date();
		this.firstMonthDay.setDate(1);
		this.lastMonthDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
		this.isButtonDisable = false;
		setTimeout(() => {
			if (this.auth.isAuth) {
				this.initDefaultParty();
				// this.checkInitApp().then((result: any) => { console.log(result); });
			}
		}, 0);
	}

	static getUOM(uom: number): string {
		const f = sd.default.uom.find(x => x.id === uom);
		return f.value || sd.default.defaultUom;
	}
	public createMask(values: number[]): number {
		let result = 0;
		for (const v of values) {
			result = result | v;
		}
		return result;
	}

	public splitMask(value: number, valid: { 'id': number; 'value': string }[]): number[] {
		const result = [];
		for (const v of valid) {
			if ((value & v.id) > 0) {
				result.push(v.id);
			}
		}
		return result;
	}

	public enumDisplay(info: any) {
		return info ? info.code + ' - ' + (info.description || '') : '';
	}

	public get static() {
		return sd.default;
	}

	public get isAdmin(): boolean {
		return this.auth.isAuth ? (this.auth.user.roles.indexOf('admin') > -1 || this.auth.user.name === 'huzzy') : this.auth.isAuth;
	}

	public get isSuperAdmin(): boolean {
		return this.auth.isAuth ? (this.auth.user.roles.indexOf('superadmin') > -1 || this.auth.user.name === 'huzzy'
			|| this.auth.user.name === 'emanuel.daviduta@dohu.ro') : this.auth.isAuth;
	}

	initDefaultParty() {
		this.defaultParty = { bu: null, ent: null, wc: null };
		// EntityStore.execute('GetSingleFilterParty', {}).then(data => {
		// 	this.defaultParty = data;
		// }, error => {
		// 	this.lg.showError(error);
		// });
	}

	// checkInitApp() {
	// 	return EntityStore.execute('CheckInitApp', {});
	// }

	/* enum values */
	public getEnum(key: string, list: { id: number; value: string }[]): { id: number; value: string }[] {
		const result = [];
		const values = this.static[key] ?? list;
		values.forEach((u: { id: number; value: string }) => {
			result.push({ id: u.id, value: this.lg.format(u.value) });
		});
		return result;
	}

	getEnumValue(code: string): CustomStore {
		const q = new EntityQuery('EnumValue').addOrderBy(['orderIndex', 'code']).
			linkEq('typeId', 'id', 'EnumType', 'code', code);
		q.fields.push('id', 'code', 'description');
		return this.entity.store(q, false, 'id');
	}
}
