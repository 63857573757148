import notify from 'devextreme/ui/notify';
import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';
import { EntityQuery, iBisEntityConfig, iBisEntityService } from '@dohu/ibis-entity';
import { iBisFileService, iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';


@Injectable({
	providedIn: 'root'
})
export class MirrorStockEditService extends iBisSecureEditService {

	public storeOptions: any;

	public data: CustomStore;
	public dataOut: CustomStore;
	public inOutType: { 'id': number; 'value': string }[];

	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService,
		private ds: DataService, private e_config: iBisEntityConfig, private file: iBisFileService) {
		super(auth, entity, config, lg);
		this.inOutType = this.getInOutType();
	}

	public getDataOut(parentId: string): void {
		const q = new EntityQuery('MirrorStock').eq('parentId', parentId).addOrderBy(['date']);
		this.dataOut = this.entity.store(q, false, 'id');
	}

	public getMirrorStockInOptions(): CustomStore {
		const q = new EntityQuery('MirrorStock').eq('parentId', null).addOrderBy(['date']);
		return this.entity.store(q, false, 'id');
	}

	public downloadReport() {
		// TODO: Checking on lastQuery function
		const q = this.storeOptions.lastQuery();
		q.reportName = 'Oglinda';
		q.reportType = 'REP_STOCL';
		q.reportCode = 'MIRROR_STOCK';
		this.file.downloadByPost(this.e_config.server + 'GenerateReport', q).then(
			() => {
				notify(this.L('FILE_DOWNLOADED', null), 'success', 2000);
			},
			() => {
				notify(this.L('DOWNLOAD_ERROR', null), 'error', 3000);
			});
	}

	createDefault(): any {
		return {};
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	reset(): void {
		this.createDefault();
	}

	protected load(): void {
		this.data = this.getData();
		this.storeOptions = this.getMirrorStockInOptions();
	}

	private getData(): CustomStore {
		const q = new EntityQuery('MirrorStock').eq('parentId', null).addOrderBy(['date']);
		return this.entity.store(q, false, 'id');
	}

	private getInOutType(): { 'id': number; 'value': string }[] {
		const list = [
			{ 'id': 0, 'value': 'COLLECT' },
			{ 'id': 1, 'value': 'GENERATE' },
			{ 'id': 2, 'value': 'IN_TREATMENT_SORT' },
			{ 'id': 3, 'value': 'INCINERATE' },
			{ 'id': 4, 'value': 'DELIVERY_TO_CLIENTS' },
			{ 'id': 5, 'value': 'OUT_TREATMENT_SORT' }
		];
		return this.ds.getEnum('inOutType', list);
	}
}
