import { iBisBaseComponent } from '@dohu/ibis-common';
import { Component, Input, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { DxTreeViewComponent } from 'devextreme-angular/ui/tree-view';
import { ResolvedData } from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';

@Component({
	selector: 'app-user-access',
	templateUrl: './user-access.component.html'
})
export class UserAccessComponent extends iBisBaseComponent {
	@Input() userId: any;
	@ViewChild('partyTree', { static: false }) partyTree: DxTreeViewComponent;
	@ViewChild('zoneTree', { static: false }) zoneTree: DxTreeViewComponent;

	public dataForm: { party: string[]; zone: string[] };
	constructor(public proxy: ProxyService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.dataForm = { party: [], zone: [] };
	}

	saveUserAccess = (ev: any) => {
		this.proxy.entity.execute('UpdateUserAccess',
			{ userId: this.userId, party: this.dataForm.party ?? [], zone: this.dataForm.zone ?? [] })
			.then(() => {
				notify(this.lg.format('SAVING_SUCCESS'), 'success', 2000);
			}).catch((error) => {
				notify(this.proxy.lg.showError(error));
			});
	};

	syncPartyTree(e: any) {
		const component = (e && e.component) || (this.partyTree && this.partyTree.instance);
		if (component && this.dataForm.party) {
			this.dataForm.party.forEach(p => {
				component.selectItem(p);
			});
		}
	}

	syncZoneTree(e: any) {
		const component = (e && e.component) || (this.zoneTree && this.zoneTree.instance);
		if (component && this.dataForm.zone) {
			this.dataForm.zone.forEach(p => {
				component.selectItem(p);
			});
		}
	}

	onFormInitialized(e: any) {
		this.proxy.party.getPartyAccess(this.userId).load().then((parties: any) => {
			if (parties) {
				this.dataForm.party = [];
				parties.forEach(party => {
					this.dataForm.party.push(party.partyId); 
				});
			}
		});
		this.proxy.geo.getUserAccessZone(this.userId).load().then((zones: any) => {
			if (zones) {
				this.dataForm.zone = [];
				zones.forEach(zone => {
					this.dataForm.zone.push(zone.zoneId);
				});
			}
		});
	}

	partyTree_itemSelectionChanged(e: any) {
		this.dataForm.party = e.component.getSelectedNodeKeys();
	}

	zoneTree_itemSelectionChanged(e: any) {
		this.dataForm.zone = e.component.getSelectedNodeKeys();
	}
}
