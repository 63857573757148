import { Component, ViewChild } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { TransporterEditService } from './transporter-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	templateUrl: 'transporter.component.html'
})
export class TransporterComponent extends iBisViewComponent {
	@ViewChild('gridTransporter') grid: DxDataGridComponent;
	public edit: TransporterEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.transporter, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'p_id';
		this.key = 'transporter';
	}
}
