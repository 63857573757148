/* eslint-disable no-trailing-spaces */
/* eslint-disable @typescript-eslint/indent */
import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { PartyEditService } from '../../../common/party/party-edit/party-edit.service';
import { iBisAuthService } from '@dohu/ibis-auth';

@Injectable({
	providedIn: 'root'
})
export class ArchiveEditService extends iBisEditService {
	public data: CustomStore;
	public parties: DataSource;
	public pWithActiv: CustomStore;
	public months: { id: number; value: string }[];
	public semesters: { id: number; value: string }[];
	public intervalReport: { id: number; value: string }[];
	public reportTypes: any;

	constructor(private entity: iBisEntityService, private party: PartyEditService, 
		private auth: iBisAuthService, private ds: DataService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'ARCHIVE';
		this.validation = 'ArchiveValidation';
		this.data = this.getData();
		this.parties = this.party.getPartyLink([1, 2, 3, 4]);

		this.months = this.getMonths();
		this.semesters = this.getSemesters();
		this.intervalReport = this.getIntervalReport();
	}

	createDefault(): any {
		return {};
	}

	getById(id: string, serverUrl?: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.entity.getById('Archive', id).then(result => {
				if (!result) {
					reject();
				} else {
					resolve(result);
				}
			}, error => this.lg.showError(error)
			);
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('Archive', id);
	}

	onSaveEv(serverUrl?: string): Promise<any> {
		this.model.date = new Date();
		return this.entity.save('Archive', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	private getData(): CustomStore {
		const q = new EntityQuery('ArchiveView').addOrderByDesc(['a.date']);
		return this.entity.store(q, false, 'a_id');
	}

	private getReportType() {
		this.entity.execute('GetReportType', {}).then((result) => {
			if (result) {
				this.reportTypes = result;
			}
		}, error => {
			this.lg.showError(error);
		});
	}

	// TODO Correct dataSource for every lookup that is using ds.static.months || semesters || reportInterval
	private getMonths(): { id: number; value: string }[] {
		const list = [
			{ 'id': 1, 'value': 'JANUARY' }, { 'id': 2, 'value': 'FEBRUARY' }, { 'id': 3, 'value': 'MARCH' },
			{ 'id': 4, 'value': 'APRIL' }, { 'id': 5, 'value': 'MAY' }, { 'id': 6, 'value': 'JUNE' },
			{ 'id': 7, 'value': 'JULY' }, { 'id': 8, 'value': 'AUGUST' }, { 'id': 9, 'value': 'SEPTEMBER' },
			{ 'id': 10, 'value': 'OCTOBER' }, { 'id': 11, 'value': 'NOVEMBER' }, { 'id': 12, 'value': 'DECEMBER' }];
		return this.ds.getEnum('months', list);
	}

	private getSemesters(): { id: number; value: string }[] {
		const list = [{ 'id': 1, 'value': 'SEM_1' }, { 'id': 2, 'value': 'SEM_2' }];
		return this.ds.getEnum('semesters', list);
	}

	private getIntervalReport(): { id: number; value: string }[] {
		const list = [{ 'id': 1, 'value': 'MONTHLY' }, { 'id': 2, 'value': 'SEMESTER_INTERVAL' }, { 'id': 3, 'value': 'ANNUAL' }];
		return this.ds.getEnum('reportInterval', list);
	}
}
