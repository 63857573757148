import notify from 'devextreme/ui/notify';
import { Component, OnInit } from '@angular/core';
import { DataService } from '../service/data.service';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../service/proxy.service';
import { AccountService } from './account.service';

@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.css']
})
export class AccountComponent extends iBisBaseComponent implements OnInit {

	availableLang: any[];
	globalVar: any;
	onSwitchInit: any;
	accountData: any = {};
	storeAccountData: any = {};
	passwordComparison: any;
	currentPasswordValidation: any;
	// onThemeChange: any;
	isReadOnly = true;
	isPassReset = false;
	checkCompact: boolean;
	onLanguageChange: (e: any) => void;

	constructor(public proxy: ProxyService, public account: AccountService, public ds: DataService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.onLanguageChange = this.onLanguageChangeEv.bind(this);
		this.globalVar = JSON.parse(localStorage.getItem('globalVarQHSE')) || {};
	}

	ngOnInit() {
		this.lg.lang().then(result => {
			if (result) {
				this.availableLang = result;
			} else {
				notify('No language available', 'error', 3000);
			}
		}, (error: any) => {
			this.ds.lg.showError(error);
		});
		this.proxy.auth.getFullUser().then(result => {
			this.accountData = result || {};
			// const theme = localStorage.getItem('localTheme');
			this.accountData.switchCompact = true; // theme === 'material.light.compact';
			this.accountData.lang = this.globalVar.lang || 'ro';
		}, error => {
			this.ds.lg.showError(error);
		});

		this.passwordComparison = () => this.accountData.newPassword;
		this.isReadOnly = true;
		// this.onThemeChange = (data) => { this.onThemeChangeEv(data, this); };
	}

	onLanguageChangeEv(e: any) {
		if (e.event) {
			this.globalVar.lang = e.value;
			localStorage.setItem('globalVarQHSE', JSON.stringify(this.globalVar));
			window.location.reload();
		}
	}

	// onThemeChangeEv(event, that) {
	//     if (event.event) {
	//         let theme;
	//         if (event.value === true) {
	//             theme = 'material.light.compact';
	//         } else {
	//             theme = 'material.light';
	//         }

	//         localStorage.setItem('localTheme', theme);
	//         location.reload();
	//     }
	// }

	onPasswordReset() {
		this.isPassReset = true;
		this.isReadOnly = false;

	}

	startEditing() {
		this.isReadOnly = false;
		Object.assign(this.storeAccountData, this.accountData);
	}

	onSaveEdit(event: any) {
		const validation = event.instance.validate();
		if (!validation.isValid) {
			notify(this.L('FIELDS_NOT_VALID', null), 'error', 3000);
			return;
		} else if (this.accountData.newPassword === '12345') {
			notify(this.L('COMMON_PASSWORD', null), 'warning', 3000);
			return;
		}

		const obj: any = {
			fullName: this.accountData.fullName,
			emailAddress: this.accountData.emailAddress,
			phone: this.accountData.phone
		};

		if (this.accountData.newPassword.length) {
			obj.correctPassword = this.accountData.newPassword.trim();
		}

		this.account.updateUserLogin(obj).then(() => {
			notify(this.L('DATA_CHANGED', null), 'success', 3000);
			this.isReadOnly = true;
			this.isPassReset = false;
		});
	}

	onSaveClose() {
		this.isReadOnly = true;
		this.isPassReset = false;
		Object.assign(this.accountData, this.storeAccountData);
	}
}
