import { Injectable } from '@angular/core';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisServerConfig } from '@dohu/ibis-common';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';

@Injectable()
export class AccountService {
	constructor(private entity: iBisEntityService, private config: iBisServerConfig, private auth: iBisAuthService) { }

	getLicenseItem(): CustomStore {
		const q = new EntityQuery('LicenseItem');
		q.link('licenseId', 'id', new EntityQuery('License').eq('appId', this.auth.companyId));
		return this.entity.store(q, false, 'id', this.config.saasServer);
	}

	getLicenseItemExtra(): CustomStore {
		const q = new EntityQuery('LicenseItemExtra');
		q.link('licenseItemId', 'id', new EntityQuery('LicenseItem'));
		return this.entity.store(q, false, 'id', this.config.saasServer);
	}

	updateUserLogin(obj: any) {
		return this.entity.save('UserLogin', this.auth.user.id, obj, this.config.saasServer);
	}
}
