import { Component, OnInit } from '@angular/core';
import { AccountService } from '../account.service';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { DataService } from '../../service/data.service';
import { ProxyService } from '../../service/proxy.service';

@Component({
	selector: 'app-history',
	templateUrl: './history.component.html',
	styleUrls: ['./history.component.css']
})
export class HistoryComponent extends iBisBaseComponent implements OnInit {
	licenseItem: any = [];
	// TODO: REMOVE dataService. it is used only for ds.static
	constructor(public proxy: ProxyService, public account: AccountService, public ds: DataService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	ngOnInit() {
		this.account.getLicenseItem().load().then((res: any) => {
			res.forEach((licenseItem: any) => {
				this.account.getLicenseItemExtra().load().then((r: any) => {
					licenseItem.extra = [];
					r.forEach((extraItem: any) => {
						switch (extraItem.typeId) {
							case 1:
								licenseItem.extra.push(this.L('WORK_CENTER', null));
								break;
						}

					});
					this.licenseItem = res;
				}, error => {
					this.lg.showError(error);
				});
				this.ds.static.subscriptionData.forEach((element: any) => {
					if (element.id === licenseItem.typeId) {
						licenseItem.name = element.name;
					}
				});
			});
			this.licenseItem = res;
		}, error => {
			this.lg.showError(error);
		});
	}
}
