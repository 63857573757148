/* eslint-disable @angular-eslint/no-output-on-prefix */
import notify from 'devextreme/ui/notify';
import { iBisAuthService } from '@dohu/ibis-auth';
import { DataService } from '../../service/data.service';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { environment } from '../../../environments/environment';
import { iBisBaseComponent, iBisUIService } from '@dohu/ibis-common';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-add-company',
	templateUrl: './add-company.component.html'
})
export class AddCompanyComponent extends iBisBaseComponent implements OnInit {
	@ViewChild('addCompanyForm') addCompanyForm: DxFormComponent;
	@Input() popupVisible: boolean;
	@Output() onClosePopup = new EventEmitter<any>();

	newCompanyData: any;
	constructor(private auth: iBisAuthService, public ds: DataService, iui: iBisUIService) {
		super(null, ds.lg, iui, null);
	}

	ngOnInit() {
		this.newCompanyData = {};
	}

	onSaveCompany = (e: any) => {
		this.auth.getFullUser().then(result => {
			const fields = ['_Companie_', '_CIF_', '_Telefon_'];
			const values = [this.newCompanyData.name, this.newCompanyData.cif, null];
			const obj = {
				registerType: 'QHSE',
				fullName: result.fullName,
				emailAddress: result.emailAddress,
				deployId: environment.deployId,
				fields: fields,
				values: values
			};
			this.auth.register(obj).then(() => {
				notify(this.L('REQUEST_PROCESS', null), 'success', 3000);
				this.newCompanyData = {};
				this.popupVisible = false;
				this.onClosePopup.emit(false);
				this.addCompanyForm.instance.resetValues();
			});
		}, error => {
			this.ds.lg.showError(error);
		});
	};

	onAddCompanyHidden(e: any) {
		this.newCompanyData = {};
		this.addCompanyForm.instance.resetValues();
		this.onClosePopup.emit(false);
	}
}
