import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { WasteTypeEditService } from '../waste-type/waste-type-edit.service';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';

@Injectable({
	providedIn: 'root'
})
export class TreatmentEditService extends iBisSecureEditService {

	public data: CustomStore;
	public dataIn: CustomStore;

	public item: any;
	public source: any;

	public type: { id: number; value: string }[];
	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService
		, private wtp: WasteTypeEditService, private ds: DataService) {
		super(auth, entity, config, lg);
		this.title = 'TREATMENT';
		this.validation = 'treatmentValidate';
	}

	public showPopup(data: any, byId?: boolean): Promise<any> {
		this.item = [];
		this.source = [];
		return super.showPopup(data, byId);
	}

	createDefault(): any {
		this.item = [];
		this.source = [];
		if (this && this.ds && this.ds.defaultParty) {
			return { date: new Date(), typeId: 3, workCenterId: this.ds.defaultParty.wc, totalQty: 0, physicalCondition: 0 };
		} else {
			return { date: new Date(), typeId: 3, totalQty: 0 };
		}
	}

	getById(id: any): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.entity.getById('Treatment', id).then((obj) => {
				if (!obj) {
					reject();
				} else {
					this.item = [];
					this.source = [];
					obj.totalQty = 0;
					this.entity.load(new EntityQuery('TreatmentItem').eq('treatmentId', id)).then((dr) => {
						this.item = dr;
						for (const q of dr) {
							obj.totalQty += q.quantity;
						}
						this.wtp.workCenterId = obj.workCenterId;
						this.wtp.selectedId = obj.wasteTypeId;
						resolve(obj);
					}, error => this.lg.showError(error));
				}
			}, error => this.lg.showError(error));
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('Treatment', id);
	}

	onRemoveItem(id: string): Promise<void> {
		return this.entity.remove('TreatmentItem', id);
	}

	onSaveEv(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			if (!this.model.typeId) {
				reject(this.lg.format('NO_TYPE_ID'));
			} else {
				if ((this.model.typeId === 3 && this.model.quantity > this.model.totalQty) ||
					this.model.typeId === 2 && this.model.quantity !== this.model.totalQty) {
					reject(this.model.typeId === 2 ? 'QUANTITY_EQ_WASTE_CODE' : 'QUANTITY_GTE_WASTE_CODE');
				} else {
					this.item.forEach((i: any) => {
						if (i.physicalCondition === undefined || i.physicalCondition == null) {
							i.physicalCondition = 0;
						}
						i.uom = this.wtp.selected.uom;
					});
					this.model.items = this.item;
					this.model.sources = this.source;
					this.model.uom = this.wtp.selected.uom;
					this.entity.save('Treatment', this.model.id, this.model).then((response) => {
						resolve(response);
					}, error => reject(error));
				}
			}
		});
	}

	reset(): void {
		this.model = this.createDefault();
	}

	public displayType(rowInfo: any): String {
		if (rowInfo) {
			return rowInfo.code + ' - ' + (rowInfo.description ?? '');
		}
		return '';
	}

	protected load(): void {
		this.data = this.getData();
		this.type = this.getType();
		this.dataIn = this.getTreatmentItemView();
	}

	private getData(): CustomStore {
		const q = new EntityQuery('TreatmentView').addOrderByDesc(['t.date']);
		return this.entity.store(q, false, 't_id');
	}

	private getTreatmentItemView(): CustomStore {
		const q = new EntityQuery('TreatmentItemView').addOrderByDesc(['ti.created']);
		return this.entity.store(q, false, 'i_id');
	}

	private getType(): { id: number; value: string }[] {
		const list = [{ 'id': 2, 'value': 'SORT' }, { 'id': 3, 'value': 'TREATMENT' }];
		return this.ds.getEnum('treatmentType', list);
	}
}
